
import React from 'react';
import {createRoot} from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

import reportWebVitals from './reportWebVitals';
import {CookiesProvider} from "react-cookie";
import OAuth2Popup from "./oauth/OAuth2Popup";
import {Route, Routes, BrowserRouter} from "react-router-dom";
import AppWithProvider from "./App";



const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <CookiesProvider>
            <BrowserRouter>
                <Routes>
                    <Route element={<OAuth2Popup />} path="/callback" />
                    <Route element={<AppWithProvider />} path="/" />
                    {/* ... your other routes ... */}
                </Routes>
            </BrowserRouter>
        </CookiesProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
