import {useCallback, useEffect} from "react";
import PlaybackTime from "./PlaybackTime";
import AudioTrackControls from "./AudioTrackControls";
import {Col, OverlayTrigger, Placeholder, Tooltip} from "react-bootstrap";
import TableOfContents from "./TableOfContents";
import AudioTrack from "./AudioTrack";

// class imports
import {
    selectCurrentChapter,
    selectAllChapters, setCurrentChapter
} from "../redux/chaptersSlice";
import {useDispatch, useSelector} from "react-redux";
import AudioProgressBar from "./AudioProgressBar";
import {selectChapterListHasFocus, setFocus, toggleFocus} from "../redux/domSlice";
import {selectCurrentTime, selectIsPlaying} from "../redux/audioPlayerSlice";
import {selectCurrentEpisode} from "../redux/currentEpisodeSlice";
import {useAudioContext} from "../hooks/AudioContext";

export default function AudioPlayer() {
    const AUDIO_REF = useAudioContext();
    const dispatch = useDispatch();
    const isPlaying = useSelector(selectIsPlaying);
    const currentTime = useSelector(selectCurrentTime);
    const chaptersFromTrack = useSelector(selectAllChapters);
    const currentEpisode = useSelector(selectCurrentEpisode);
    const currentChapter = useSelector(selectCurrentChapter);
    const allChapters = useSelector(selectAllChapters);
    const chapterListHasFocus = useSelector(selectChapterListHasFocus);

    // Set className of TableOfContents, depending on DOM state
    const tocClass = [];
    tocClass.push("table-of-contents");
    chapterListHasFocus ? tocClass.push("table-of-contents--expanded") : tocClass.push("table-of-contents--collapsed");


    // Update the CurrentChapter display (in AudioPlayer.js)
    // currentTime can be debounced to update less frequently for improved performance:
    // use: debounceCurrentTime for that purpose if needed

    useEffect(() => {
        if(isPlaying) {
            let chap = chaptersFromTrack;
            let currentChap = chap?.findIndex((e) => {
                return e.startTime > AUDIO_REF.current?.currentTime
            })

            if (currentChap > 0 && chap[currentChap - 1]?.chapterTitle !== currentChapter) dispatch(setCurrentChapter(chap[currentChap - 1]?.chapterTitle || null));
        }
    },[currentChapter, dispatch, AUDIO_REF, chaptersFromTrack,currentEpisode,isPlaying,currentTime])


    /**
     * Toggle display state of the Table Of Contents.
     * setToggleIsExpanded has been removed.
     * @type {(function(): void)|*}
     */
    const toggleTOC = useCallback( () => {
            if(allChapters && allChapters.length > 3) {
                dispatch(toggleFocus("chapterList"))
            }
        }, [allChapters,dispatch]
    );

    useEffect(() => {

        /**
         * Substitute missing closing button on the chapter list
         * Clicking anywhere outside the list will close the view
         * potentially causing issues with click behaviour on the page
         * @type {(function(): void)|*}
         */

        const handleClickOutside =
            () => {
                if (chapterListHasFocus ) { // && !TOC.current.contains(e.target)) {
                    // dispatch(setFocus("player"))
                    toggleTOC();
                }
            }

        if (chapterListHasFocus) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [toggleTOC, chapterListHasFocus]);


    /**
     * Display the currently playing chapter
     * @returns {JSX.Element}
     * @constructor
     */
    function CurrentChapter() {

        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" className="current-chapter-tooltip" {...props}>
                <span>Hier gelangst Du zum Inhaltsverzeichnis.</span>
            </Tooltip>
        );

        /**
         * JSX element returns the currently playing chapter based on state currentChapter set
         * @type {JSX.Element}
         */
        const chapter = (
            <h3 className="current-chapter clickable"
                onClick={() => {
                    if(allChapters?.length > 3) {
                        // dispatch(setTocIsExpanded(true));
                        dispatch(setFocus("chapterList"))
                    }
                }}>
                {currentChapter}
            </h3>
        )

        return (
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                {chapter}
            </OverlayTrigger>
        );
    }



    return(
        <div className="audio-track-control">
            {
                currentChapter
                ? ( <CurrentChapter /> )
                : ( <Placeholder as="h3" >
                        <Placeholder xs={8} bg={"secondary"} style={{width: "10rem"}} />
                    </Placeholder>
            )}
            <Col className={ tocClass.join(" ") } >
                <TableOfContents />
            </Col>
            <AudioProgressBar />
            <AudioTrack />
            <PlaybackTime />
            <AudioTrackControls />

        </div>
    )

}