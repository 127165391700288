import {useRef} from "react";
import { setIsPlaying, selectCurrentTime, selectDuration } from "../redux/audioPlayerSlice";
import { selectHasRssError } from "../redux/episodesSlice";
import {useDispatch, useSelector} from "react-redux";
import {useAudioContext} from "../hooks/AudioContext";

export default function AudioProgressBar ()
 {
    const dispatch = useDispatch();
    const hasRssError = useSelector(selectHasRssError);
    const currentTime = useSelector(selectCurrentTime);
    const duration = useSelector(selectDuration);

    const AUDIO_REF = useAudioContext();

    const sliderBar = useRef(null)
    const progressBarRect = sliderBar.current ? sliderBar.current.getBoundingClientRect() : null;
    let moveSliderFunc;
    let audioBarHandleId = "audio-bar-handle";

    function handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setIsPlaying(false));
        if (e.target.id === audioBarHandleId) {
            moveSliderFunc = moveSlider; // Store the function reference
            document.addEventListener('pointermove', moveSliderFunc);
            document.addEventListener("pointerup", releaseHandle);

        } else if (e.target.classList.contains("progress-slider") || e.target.id === "played") {
            jumpToTime(e);
            document.addEventListener("pointerup", releaseHandle);
        }
    }

    function jumpToTime(e) {
        const progress = (e.clientX - progressBarRect.left) / progressBarRect.width;
        const time = AUDIO_REF.current?.duration * progress;
        AUDIO_REF.current.currentTime = time;
    }

    function moveSlider(e){
        const progress = Math.max(0, Math.min((e.clientX - progressBarRect.left) / progressBarRect.width, 1));
        const time = AUDIO_REF.current?.duration * progress;
        AUDIO_REF.current.currentTime = time;
    }

    function releaseHandle(e) {
        document.removeEventListener("pointerup", releaseHandle);

        if(moveSliderFunc) {
            document.removeEventListener('pointermove', moveSliderFunc); // Use the stored function reference
        }
        if(e.type==="pointerup") resumePlayback()
    }

    function resumePlayback() {
        dispatch(setIsPlaying(true));
    }

    return (
        <div className="progress-slider"
             onPointerDown={hasRssError ? null : handleClick}
             onMouseLeave={hasRssError ? null : releaseHandle}
             ref={sliderBar}
        >
            <span className="progress-slider__played" id="played" style={{width: `${currentTime / duration * 100}%`}}></span>
            <span className="progress-slider__buffered" id="buffered" style={{width: "0%"}}></span>
            <div className="progress-slider__handle " id={audioBarHandleId} style={{left: `${currentTime / duration * 100}%`}} onPointerDown={hasRssError ? null : handleClick}></div>
        </div>
    );
}