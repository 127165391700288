
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist/es/constants';

/***
 * Reducers needed for:
 * Steady Auth
 * Steady User *may persist if machine is trusted*
 * Episodes
 * Current Episode
 * Chapters
 * Audio Player
 */

import audioPlayerReducer from './audioPlayerSlice';
import chaptersReducer from './chaptersSlice';
import currentEpisodeReducer from './currentEpisodeSlice';
import domReducer from './domSlice';
import episodesReducer from './episodesSlice';
import steadyAuthReducer from './steadyAuthSlice';
import steadyUserReducer from './steadyUserSlice';

const episodesPersistConfig = {
    key: "episodes",
    storage,
    whitelist: ["favourites"],
};

const persistedEpisodesReducer = persistReducer(episodesPersistConfig, episodesReducer);

const rootReducer = combineReducers({
    audioPlayer: audioPlayerReducer,
    chapters: chaptersReducer,
    currentEpisode: currentEpisodeReducer,
    dom: domReducer,
    episodes: persistedEpisodesReducer,
    steadyAuth: steadyAuthReducer,
    steadyUser: steadyUserReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["steadyUser", "steadyAuth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

const persistor = persistStore(store);

export { store, persistor };
