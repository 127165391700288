import {useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTime, selectDuration} from "../redux/audioPlayerSlice";

export default function PlaybackTime() {

    const duration = useSelector(selectDuration);
    const currentTime = useSelector(selectCurrentTime);
    let timePlayed = formatTime(currentTime);

    const useToggle = ( initialState ) => {
        const [isRemaining, setIsRemaining] = useState(false);

        const toggler = () => { setIsRemaining(!isRemaining) };
        return [isRemaining, toggler]
    };

    const [toggle, setToggle] = useToggle();

    let timeSpanRight = toggle ? formatTime(duration - currentTime) : formatTime(duration);
    let timeSpanRightClass = toggle ? "playback-time__remaining" : "playback-time__duration";

    // Helper function to format time in MM:SS format
    function formatTime(timeInSeconds) {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    return(
        <div className="playback-time">
            <div className="playback-time__played" id="audio-time-left" >
                <span>{timePlayed}</span>
            </div>
            <div className={timeSpanRightClass} id="audio-time-right" style={{cursor: "pointer"}} onClick={ setToggle }>
                <span>{timeSpanRight}</span>
            </div>
        </div>
    )
}


/***

import {useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTime, selectDuration} from "../redux/audioPlayerSlice";

export default function PlaybackTime(props) {

    const duration = useSelector(selectDuration);
    const currentTime = useSelector(selectCurrentTime);
    let timePlayed = formatTime(currentTime);

    const useToggle = ( initialState ) => {
        const [isRemaining, setIsRemaining] = useState(false);

        const toggler = () => { setIsRemaining(!isRemaining) };
        return [isRemaining, toggler]
    };

    const [toggle, setToggle] = useToggle();


    let timeSpanRight = toggle ? formatTime(duration - currentTime) : formatTime(duration);
    let timeSpanRightClass = toggle ? "audio-time-remaining" : "audio-time-duration";

    // Helper function to format time in MM:SS format
    function formatTime(timeInSeconds) {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    return(
            <div className="audio-player-playback-time">
                <div className="audio-time-played" id="audio-time-left" >
                    <span>{timePlayed}</span>
                </div>
                <div className={timeSpanRightClass} id="audio-time-right" style={{cursor: "pointer"}} onClick={ setToggle }>
                    <span>{timeSpanRight}</span>
                </div>
            </div>
        )
}

 **/