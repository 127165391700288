// chaptersSlice.js
import { createSlice } from "@reduxjs/toolkit";

const chaptersSlice = createSlice({
    name: "chapters",
    initialState: {
        currentChapter: null,
        allChapters: [],
        isTocExpanded: false,
    },
    reducers: {
        setCurrentChapter: (state, action) => {
            state.currentChapter = action.payload;
        },
        setAllChapters: (state, action) => {
            state.allChapters = action.payload;
        },
        setTocIsExpanded: (state) => {
            state.isTocExpanded = true;
        },
        toggleIsTocExpanded: (state) => {
            state.isTocExpanded = !state.isTocExpanded;
        }
    },
});

export const { setCurrentChapter, setAllChapters, setTocIsExpanded, toggleIsTocExpanded } = chaptersSlice.actions;

export const selectCurrentChapter = (state) => state.chapters.currentChapter;
export const selectAllChapters = (state) => state.chapters.allChapters;
export const selectIsTocExpanded = (state) => state.chapters.isTocExpanded;

export default chaptersSlice.reducer;
