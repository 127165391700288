
export async function fetchRssPaidContents(rssFeed,allEpisodes) {
    // setRssHasUpdated(false);
    // const dispatch = useDispatch();

    try {
        const response = await fetch(rssFeed);
        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }
        const data = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "application/xml");

        // Create a map of guid to src from xmlDoc
        const guidToSrcMap = {};
        Array.from(xmlDoc.querySelectorAll("item")).forEach((item) => {
            const guid = item.querySelector("guid")?.textContent;
            const src = item.querySelector("link")?.textContent || "";
            if (guid) {
                guidToSrcMap[guid] = src;
            }
        });

        const updatedEpisodes = allEpisodes?.map((episode) => {
            // console.log(episode.guid, guidToSrcMap)
            // Check if the guid exists in the guidToSrcMap
            if (guidToSrcMap.hasOwnProperty(episode.guid)) {

                return {
                    ...episode,
                    src: guidToSrcMap[episode.guid],
                };
            }

            // If no matching item is found, return the original episode
            return episode;
        });

        return updatedEpisodes;

    } catch (error) {
        console.error("Error fetching RSS data:", error);
        throw error;
    }
}