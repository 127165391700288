import {Placeholder} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectCurrentSubtitle, selectCurrentTitle, selectCurrentXid} from "../redux/currentEpisodeSlice";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function EpisodeTitle() {

    const currentTitle = useSelector(selectCurrentTitle);
    const currentSubtitle = useSelector(selectCurrentSubtitle);
    const currentXid = useSelector(selectCurrentXid);

    if(!currentTitle) {
        return (
            <div className="audio-track-title">
                <Placeholder as="h2" animation="glow">
                    <Placeholder xs={8} bg={"secondary"} />
                </Placeholder>
                <Placeholder as="h1" animation="glow">
                    <Placeholder xs={2} bg={"secondary"} /> <Placeholder xs={7} bg={"secondary"} />
                    <Placeholder xs={4} bg={"secondary"} /> <Placeholder xs={5} bg={"secondary"} />
                </Placeholder>
            </div>
        )
    }

    const handleClick = () => {
        if(currentXid){
            window.open(currentXid, "_blank");
        }
    }

    return (
        <div className={`audio-track-title ${currentXid ? "clickable" : ""}`} onClick={handleClick}>
            <h2 id="audio-track-title">{currentTitle}</h2>
            <h1 id="audio-track-subtitle">{currentSubtitle}</h1>
            {currentXid ? <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> : ""}
        </div>
    );
}

export default EpisodeTitle;