// steadyAuthSlice.js
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

export const renewToken = createAsyncThunk('steadyAuth/renewToken', async (_, { getState }) =>  {

    const objectToQuery = (object) => {
        return new URLSearchParams(object).toString();
    };

    const formatExchangeCodeForTokenServerURL = (
        serverUrl,
        clientId,
        refreshToken,
        redirectUri
    ) => {
        return `${serverUrl}?${objectToQuery({
            client_id: clientId,
            refresh_token: refreshToken,
            redirect_uri: redirectUri,
        })}`;
    };

    const refreshToken = getState().steadyAuth?.refreshToken;

    const response = await fetch(
        formatExchangeCodeForTokenServerURL(
            `/api/refresh`,
            CLIENT_ID,
            refreshToken,
            REDIRECT_URI
        ),
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            }
        }
    );

    return response.data
});

const initialState = {
    token: null,
    expiresAt: null,
    refreshToken: null,
    isAuthenticated: false,
    lastAuthenticated: null,
};

const steadyAuthSlice = createSlice({
    name: "steadyAuth",
    initialState,
    reducers: {
        setAccessToken: (state, action) => {
            state.token = action.payload;
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
        },
        setAuthState: (state, action) => {
            state.token = action.payload.token;
            state.expiresAt = action.payload.expiresAt;
            state.refreshToken = action.payload.refreshToken;
            state.isAuthenticated = action.payload.isAuthenticated || true;
            state.lastAuthenticated = action.payload.lastAuthenticated;
        },
        setTokenExpiresAt: (state,action) => {
            state.expiresAt = action.payload;
        },
        setUnAuth: (state) => {
            state.token = null;
            state.expiresAt = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
            state.lastAuthenticated = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(renewToken.fulfilled, (state, action) => {
            state.token = action.payload.access_token;
            state.expiresAt = new Date().getTime() + action.payload.expires_in * 1000;
            state.refreshToken = action.payload.refresh_token;
            state.isAuthenticated = true;
            state.lastAuthenticated = new Date().getTime();
        });
    },
});

export const { setAccessToken, setRefreshToken, setAuthState, setUnAuth } = steadyAuthSlice.actions;

export const selectAccessToken = (state) => state.steadyAuth.token;
export const selectIsAuthenticated = (state) => state.steadyAuth.isAuthenticated;
export const selectTokenExpiresAt = (state) => state.steadyAuth.expiresAt;

export default steadyAuthSlice.reducer;
