import * as jsmediatags from '../../node_modules/jsmediatags/dist/jsmediatags.min.js';

export default function readTrackTags(currentEpisodeSrc) {

    const HREF = currentEpisodeSrc;
    const DATA = process.env.REACT_APP_BASE64_FALLBACK_DATA;
    const FORMAT = process.env.REACT_APP_BASE64_FALLBACK_FORMAT;

    // Helper function to format time in MM:SS format
    function formatTime(timeInSeconds) {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }



    return new Promise((resolve, reject) => {
        jsmediatags?.read(HREF, {
            onSuccess: function(tag) {
                let CTOC;
                let data;
                let format;
                let fallback = false;

                try {
                    CTOC = tag.tags?.CHAP?.map(i => {
                        return {
                            startTime: i.data.startTime/1000,
                            startTimeFormatted: formatTime(i.data.startTime/1000 ),
                            chapterTitle: i.data.subFrames.TIT2[0].data
                        }
                    });
                } catch (error) {
                    console.log(error);
                }

                try {
                    const picture = tag.tags?.picture;
                    if (picture) {
                        data = picture.data;
                        format = picture.format;
                    } else {
                        // Load base64 fallback from .env
                        data = DATA;
                        format = FORMAT;
                        fallback=true;
                    }
                } catch (error) {
                    // Load base64 fallback from .env
                    data = DATA;
                    format = FORMAT;
                    fallback = true;
                }

                const title = tag.tags.title;
                resolve([CTOC, data, format, fallback, title]);
            },
            onError: function(error) {
                console.log(error);
                resolve([null,DATA,FORMAT,true])
                reject(error);
            },
        });
    })
        .catch(() => {
            return ([null,DATA,FORMAT,true])
        });

}