// audioPlayerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const audioPlayerSlice = createSlice({
    name: "audioPlayer",
    initialState: {
        isPlaying: false,
        played: 0,
        duration: 0,
        currentTime: 0,
    },
    reducers: {
        setIsPlaying: (state, action) => {
            state.isPlaying = action.payload;
        },
        togglePlayback: (state) => {
            state.isPlaying = !state.isPlaying;
        },
        setPlayed: (state, action) => {
            state.played = action.payload;
        },
        setDuration: (state, action) => {
            state.duration = action.payload;
        },
        setCurrentTime: (state, action) => {
            state.currentTime = action.payload || 0;
        },
        setPlayerReset: (state) => {
            state.isPlaying = false;
            state.played = 0;
            state.duration = 0;
            state.currentTime = 0;
        }
    },
});

export const { setIsPlaying, togglePlayback, setPlayed, setDuration, setCurrentTime, setPlayerReset } = audioPlayerSlice.actions;

export const selectIsPlaying = (state) => state.audioPlayer.isPlaying;
export const selectPlayed = (state) => state.audioPlayer.played;
export const selectDuration = (state) => state.audioPlayer.duration;
export const selectCurrentTime = (state) => state.audioPlayer.currentTime;

export default audioPlayerSlice.reducer;