import {useDispatch, useSelector} from "react-redux";
import {setIsPlaying} from "../redux/audioPlayerSlice";
import {useAudioContext} from "../hooks/AudioContext";
import {selectAllChapters} from "../redux/chaptersSlice";
import {selectChapterListHasFocus} from "../redux/domSlice";
import {selectAudioSrc} from "../redux/currentEpisodeSlice";

export default function TableOfContents () {

    const dispatch = useDispatch();
    const allChapters = useSelector(selectAllChapters);
    const chapterListHasFocus = useSelector(selectChapterListHasFocus);
    const audioSrc = useSelector(selectAudioSrc)

    const AUDIO_REF = useAudioContext();

    /**
     * Function sets currentTime to startTime of selected chapter.
     * Currently passing event, possibly passing e.startTime from listItems directly in the future.
     * @param e
     */
    function jumpToChapter(e) {
        dispatch(setIsPlaying(false));
        AUDIO_REF.current.currentTime = e.currentTarget.firstElementChild.innerText;
        dispatch(setIsPlaying(true));
    }

    if(allChapters?.length < 1) return null

    let listItems = [];
    allChapters?.forEach((e) => {
        listItems.push(
            <li key={e.startTime}  id="table-of-contents__jump-to-second" style={{cursor: audioSrc ? "pointer" : "not-allowed"}} onClick={audioSrc ? jumpToChapter : null} className={"table-of-contents__item clickable"}>
              <span
                  className="table-of-contents__hidden-time"
                  id="table-of-contents__jump-to-second"
                  style={{display: 'none'}}
              >
                  {e.startTime}
              </span>
                <span
                    className="table-of-contents__jump-to-time"
                    style={{cursor: "pointer"}}
                >
                  {e.startTimeFormatted}
              </span>&nbsp;
                <span>
                  – {e.chapterTitle}
              </span>
            </li>
        );
    });

    return(
        <ul className={chapterListHasFocus ? "table-of-contents__list table-of-contents__list--expanded" : "table-of-contents__list table-of-contents__list--collapsed"}>
            {listItems}
        </ul>
    )
}


/*

import {useDispatch, useSelector} from "react-redux";
import {setIsPlaying} from "../redux/audioPlayerSlice";
import {useAudioContext} from "../hooks/AudioContext";
import {selectAllChapters} from "../redux/chaptersSlice";
import {selectChapterListHasFocus} from "../redux/domSlice";

export default function TableOfContents (props) {

    const dispatch = useDispatch();
    const allChapters = useSelector(selectAllChapters);
    const chapterListHasFocus = useSelector(selectChapterListHasFocus);

    const AUDIO_REF = useAudioContext();

    // create listItems
    // CTOC exports as object with startTime, startTimeFormatted and chapterTitle

    function jumpToChapter(e) {
        dispatch(setIsPlaying(false));
        AUDIO_REF.current.currentTime = e.currentTarget.firstElementChild.innerText;
        dispatch(setIsPlaying(true));
        /*setTimeout(() => {
            props.toggleTOC();
        },3000);
    }

    // catch case episodes havent loaded yet
    if(allChapters.length < 1) return null


    let listItems = [];
    allChapters?.forEach((e) => {
        listItems.push(
            <li key={e.startTime}  id="jump-to-second" onClick={jumpToChapter} className={"clickable"}>
              <span
                  className="hide"
                  id="jump-to-second"
                  style={{display: 'none'}}
              >
                  {e.startTime}
              </span>
              <span
                  className="jump-to-time"
                  style={{cursor: "pointer"}}
              >
                  {e.startTimeFormatted}
              </span>&nbsp;
              <span>
                  – {e.chapterTitle}
              </span>
            </li>
        );
    });


    return(
        <ul className={!chapterListHasFocus && "collapsed"}>
            {listItems}
        </ul>
    )

}

 */