// client/src/components/Login.js

import React, {useEffect} from 'react';
import {useOAuth2} from '../hooks/useOAuth2';
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    selectTokenExpiresAt,
    setAuthState
} from "../redux/steadyAuthSlice";
import {setUserData} from "../redux/steadyUserSlice";

const REDIRECT_BASE_URI = process.env.REACT_APP_REDIRECT_BASE_URI;

let payload = {
    authorizeUrl:"https://steadyhq.com/oauth/authorize", // authorizeUrl
    clientId:"540cb189-570a-413d-b043-90034b45e72c", // clientId
    redirectUri:`${REDIRECT_BASE_URI}/callback`, // redirectUri
    scope:"read" // scope
};

const LoginWithOAuth2 = (props) => {

    const tokenExpiresAt = useSelector(selectTokenExpiresAt);
    const dispatch = useDispatch();
    const setCookies = props.setCookies


    const { getAuth, loading, data } = useOAuth2(payload);


    useEffect(() => {
        if(data && !data.errors && data !== "error") {

            dispatch(setUserData({
                userFirstName: data?.info['first-name'] ?? "",
                userLastName: data?.info['last-name'] ?? "",
                userId: data?.info?.id
            }));

            if(data.access_token) setCookies('steady-token',data.access_token);

            // Set Token Expiration to now + x seconds
            const expiresInMilliseconds = data.expires_in * 1000 ?? 0;
            const nowDate = Date.now();
            data["expires_at"] = nowDate + expiresInMilliseconds;
            const newAuthState = {
                token: data?.access_token,
                expiresAt: data?.expires_at,
                refreshToken: data?.refresh_token,
                isAuthenticated: true,
                lastAuthenticated: nowDate,
            }

            dispatch(setAuthState(newAuthState));
            localStorage.setItem("steady-user",JSON.stringify(data));
        }
    },[dispatch,data,setCookies]);




    // Set up an interval to check the token expiration
    useEffect(() => {

        // Function to check if the token is expired
        const isTokenExpired = () => {
            if (!tokenExpiresAt) {
                return false;
            }

            const currentTime = new Date();
            return currentTime >= tokenExpiresAt;
        };

        const intervalDuration = 60 * 1000; // Check every minute (60,000 milliseconds)

        const intervalId = setInterval(() => {
            if (isTokenExpired()) {
                console.info("Token is expired. Refresh or take other action.");

                // Clear the interval once the token has expired
                clearInterval(intervalId);
            }
        }, intervalDuration);

        // Clean up the interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, [tokenExpiresAt]);




        const handleClick = () => {
        if (!loading) {
            getAuth();
        }
    };


    return (
            <Button className="col-auto steady-button" id="steady-button" variant={props.saved ? "success" : "primary"} type="submit" onClick={handleClick} disabled={loading} data-toggle="popover" title="Data saved" data-content="We have saved your RSS feed and your address in your browser.">
                <img src="https://assets.steadyhq.com/gfx/brand2019/steady_icon_white.svg" alt="" aria-hidden="true" style={{width: "13px"}} />
                <span className="backend_publication_steady_login_settings__preview__button__separator" >
                    </span>
                <span className="w-100">Login</span>
            </Button>
    );
};

export default LoginWithOAuth2;
