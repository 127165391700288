
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPause, faPlay, faStop, faVolumeHigh, faVolumeLow, faVolumeXmark} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { useAudioContext } from '../hooks/AudioContext';
import {useDispatch, useSelector} from "react-redux";
import {
    selectIsPlaying,
    setIsPlaying,
    togglePlayback
} from "../redux/audioPlayerSlice";
import {selectAudioSrc} from "../redux/currentEpisodeSlice";

export default function AudioTrackControls() {
    const VOL_DIV_REF = useRef(null);
    const AUDIO_REF = useAudioContext();
    const isPlaying = useSelector(selectIsPlaying);
    const audioSrc = useSelector(selectAudioSrc);

    const [volume, setVolume] = useState(0.5);
    const [playbackRate] = useState(1.5);

    const dispatch = useDispatch() ;

    useEffect(() => {
        if (AUDIO_REF.current) {
            AUDIO_REF.current.volume = volume;
        }
    }, [volume, AUDIO_REF]);

    useEffect(() => {
        if (AUDIO_REF.current) {
            AUDIO_REF.current.playbackRate = playbackRate;
        }
    }, [playbackRate, AUDIO_REF]);



    function play() {
        if ( AUDIO_REF.current) {
            dispatch(setIsPlaying(true))
        }
    }

    function stop() {
        if( AUDIO_REF.current) {
            dispatch(setIsPlaying(false));
            AUDIO_REF.current.currentTime = 0;
        }
    }

    function showVolume() {
        if (VOL_DIV_REF.current) VOL_DIV_REF.current.style.display = 'block';
    }

    function hideVolume() {
        if (VOL_DIV_REF.current) VOL_DIV_REF.current.style.display = 'none';
    }

    function clickVolume(e) {
        e.preventDefault();
        if (e.target.type === 'range') return null;
        setVolume((prev) => (prev > 0 ? 0 : 0.5));
        hideVolume();
    }

    function slideVolume(e) {
        e.preventDefault();
        const vol = e.currentTarget.value;
        setVolume(vol);
    }


    function getVolumeIcon(volume) {
        if (volume === 0 || volume === '0') {
            return faVolumeXmark;
        } else if (volume > 0 && volume <= 0.5) {
            return faVolumeLow;
        } else {
            return faVolumeHigh;
        }
    }


    let classStop = ["audio-controls__stop"];
    let classRewind = ["audio-controls__rewind-ten"];
    let classPlayPause = ["audio-controls__play-pause"];
    let classForward = ["audio-controls__forward-ten"];
    let classVolume = ["audio-controls__volume"];
    let classIcon = ["audio-controls__icon"];
    classIcon.push( audioSrc ?  "audio-controls__icon--allowed" : "audio-controls__icon--disallowed" );



    return (
        <div className="audio-controls">
            <div className={classStop.join(" ")} onClick={stop}>
                <FontAwesomeIcon icon={faStop} className={classIcon.join(" ")} />
            </div>
            <div className={classRewind.join(" ")} onClick={audioSrc ? (e) => {
                e.preventDefault();
                AUDIO_REF.current.currentTime -= 10
            } : null }>
        <span>
          <svg
              className={classIcon.join(" ")}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              strokeWidth="4"
              stroke="#222"
              fill="none"
          >
            <path
                strokeLinecap="round"
                d="M9.57 15.41l2.6 8.64 8.64-2.61M26.93 41.41V23a.09.09 0 00-.16-.07s-2.58 3.69-4.17 4.78"
            ></path>
            <rect x="32.19" y="22.52" width="11.41" height="18.89" rx="5.7"></rect>
            <path
                d="M12.14 23.94a21.91 21.91 0 11-.91 13.25"
                strokeLinecap="round"
            ></path>
          </svg>
        </span>
            </div>
            <div
                className={classPlayPause.join(" ")}
                onClick={!audioSrc ? null : isPlaying ? () => dispatch(togglePlayback()) : play}
                style={{ cursor: 'pointer' }}
            >
                {isPlaying
                    ? <FontAwesomeIcon className={classIcon.join(" ")} icon={faPause} />
                    : <FontAwesomeIcon className={classIcon.join(" ")} icon={faPlay} />
                }

            </div>
            <div className={classForward.join(" ")} onClick={audioSrc ? (e) => {
                e.preventDefault();
                AUDIO_REF.current.currentTime += 10
            } : null }>
        <span>
          <svg
              className={classIcon.join(" ")}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              strokeWidth="4"
              stroke="#333"
              fill="none"
          >
            <path
                d="M23.93 41.41V23a.09.09 0 00-.16-.07s-2.58 3.69-4.17 4.78"
                strokeLinecap="round"
            ></path>
            <rect x="29.19" y="22.52" width="11.41" height="18.89" rx="5.7"></rect>
            <path
                strokeLinecap="round"
                d="M54.43 15.41l-2.6 8.64-8.64-2.61M51.86 23.94a21.91 21.91 0 10.91 13.25"
            ></path>
          </svg>
        </span>
            </div>
            <div
                className={classVolume.join(" ")}
                style={{ cursor: 'pointer' }}
                onMouseEnter={showVolume}
                onClick={clickVolume}
            >
                <div className="audio-controls__volume-vertical" ref={VOL_DIV_REF}>
                    <input
                        type="range"
                        min={0}
                        max={1}
                        step={0.1}
                        value={volume}
                        id="volume-slider"
                        onChange={slideVolume}
                        onMouseLeave={hideVolume}
                    />
                </div>
                <span>
          <FontAwesomeIcon icon={getVolumeIcon(volume)} className="audio-controls__icon" />
        </span>
            </div>
        </div>
    );
}