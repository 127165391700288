import logo from "../logo.svg";
import {selectArtwork, selectCurrentTitle} from "../redux/currentEpisodeSlice";
import {useSelector} from "react-redux";

function Artwork() {
    // load tracks artwork into object
    const artwork = useSelector(selectArtwork);
    const currentTitle = useSelector(selectCurrentTitle);
    let src;
    let alt = "Amboss-Audio Cover";

    // exit if
    if (!currentTitle) {
        return(
            <div className="audio-track-cover-wrapper loader">
                <img id="audio-track-cover" src={logo} alt={alt} />

            </div>
        )
    } else if (artwork && artwork.data && artwork.fallback === false) {
        let base64String = "";
        for (let i = 0; i < artwork.data.length; i++) {
            base64String += String.fromCharCode(artwork.data[i]);
        }
        src = `data:${artwork.format};base64,${window.btoa(base64String)}`;
    } else {
        src = `data:${artwork.format};base64,${artwork.data}`;
    }


    return (
        <div className="audio-track-cover-wrapper">
            <img id="audio-track-cover" src={src} alt={alt} />

        </div>
    );
}

export default Artwork;