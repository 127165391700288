import {useCallback, useEffect, useRef, useState} from "react";

import { useAudioContext } from "../hooks/AudioContext";
import { selectIsPlaying, setCurrentTime, setDuration, setIsPlaying} from "../redux/audioPlayerSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectAudioSrc} from "../redux/currentEpisodeSlice";

function AudioTrack() {

    const dispatch = useDispatch() ;
    const isPlaying = useSelector(selectIsPlaying);
    const audioSrc = useSelector(selectAudioSrc);
    const playAnimationRef = useRef();
    const AUDIO_REF = useAudioContext();

    const [audioElement, setAudioElement] = useState(null);

    // Update the audioElement state whenever AUDIO_REF.current changes
    useEffect(() => {
        setAudioElement(AUDIO_REF.current);
    }, [AUDIO_REF]);

    useEffect(() => {
        if (!audioElement) {
            return;
        }

        let previousTime = 0;

        const handleTimeUpdate = () => {
            const currentTime = audioElement.currentTime;

            if (Math.abs(currentTime - previousTime) >= 1) {
                dispatch(setCurrentTime(currentTime));
                previousTime = currentTime;
            }
        };

        audioElement.addEventListener('timeupdate', handleTimeUpdate);

        // Clean up the event listener when the component is unmounted or the audioElement changes
        return () => {
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [audioElement, dispatch]);



    const repeat = useCallback(() => {
        playAnimationRef.current = requestAnimationFrame(repeat);

    }, []);

    // Subscribing to isPlaying state updates, resuming playback if true.
    // If playback fails isPlaying is reset to false
    useEffect(() => {
        if (isPlaying && audioSrc) {
            AUDIO_REF.current?.play()
                .catch(() => {
                    dispatch(setIsPlaying(false));
                    console.error("Playback failed.")
                })
            playAnimationRef.current = requestAnimationFrame(repeat);
        } else {
            AUDIO_REF.current?.pause();
            cancelAnimationFrame(playAnimationRef.current);
        }
    }, [AUDIO_REF,dispatch,isPlaying, repeat, audioSrc]);

    const onLoadedMetadata = () => {
        dispatch(setDuration(AUDIO_REF.current?.duration));
    }

    return (
        <audio
            id="audio-player"
            src={audioSrc}
            ref={AUDIO_REF}
            preload="metadata"
            onLoadedMetadata={onLoadedMetadata}
            playsInline={true}></audio>
    );
}

export default AudioTrack