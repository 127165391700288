// steadyUserSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userFirstName: null,
    userLastName: null,
    userId: null,
    userMail: localStorage.getItem("userMail") || null,
    userRssFeed: localStorage.getItem("rssFeed") || null,
    userPreferredLang: "de",
};

const steadyUserSlice = createSlice({
    name: "steadyUser",
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.userFirstName = action.payload.userFirstName;
            state.userLastName = action.payload.userLastName;
            state.userId = action.payload.userId;
            state.userMail = action.payload.userMail;
        },
        setUserMail: (state,action) => {
            state.userMail = action.payload;
        },
        setUserRssFeed: (state, action) => {
            state.userRssFeed = action.payload;
        },
        setUserPreferredLang: (state, action) => {
            state.userPreferredLang = action.payload;
        }
    },
});

export const { setUserData , setUserMail, setUserRssFeed, setUserPreferredLang } = steadyUserSlice.actions;

export const currentUser = (state) => state.steadyUser;

export const selectUserFirstName = (state) => state.steadyUser.userFirstName;
export const selectUserMail = (state) => state.steadyUser.userMail;
export const selectUserRssFeed = (state) => state.steadyUser.userRssFeed;
export const selectUserPreferredLang = (state) => state.steadyUser.userPreferredLang;

export default steadyUserSlice.reducer;
