// OAuth2Popup.jsx
import {useEffect, useState} from 'react';
import {ProgressBar} from "react-bootstrap";

const OAUTH_STATE_KEY = 'react-use-oauth2-state-key';
const OAUTH_RESPONSE = 'react-use-oauth2-response';

const checkState = (receivedState) => {
    const state = sessionStorage.getItem(OAUTH_STATE_KEY);
    return state === receivedState;
};

const queryToObject = (query) => {
    const parameters = new URLSearchParams(query);
    return Object.fromEntries(parameters.entries());
};

const OAuth2Popup = (props) => {

    const [errorMsg, setErrorMsg] = useState(null);
    const [progress, setProgress] = useState(Math.floor(Math.random()*20))

    useEffect(() => {
        const incrementProgress = () => {
            if (progress < 95) {
                // Increment progress by a random value between 1 and 5
                setProgress((prev) => prev + Math.floor(Math.random() * 4 + 1));
            }
        };

        // Call incrementProgress every 100 milliseconds
        const interval = setInterval(incrementProgress, 500);

        // Clean up the interval when the component is unmounted
        return () => {
            clearInterval(interval);
        };
    }, [progress]);

    const {
        Component = (
            <div style={{ margin: '12px' }} data-testid="popup-loading">
                {!errorMsg && (
                    <div>
                        <p>Loading...</p>
                        <ProgressBar now={progress} />
                    </div>
                )}
                { errorMsg && (
                    <div>
                        <p>An error occurred!</p>
                        <p>{errorMsg}</p>
                    </div>
                )}
            </div>
        ),
    } = props;

    // On mount
    useEffect(() => {
        const payload = queryToObject(window.location.search.split('?')[1]);
        const state = payload && payload.state;
        const error = payload && payload.error;

        if (!window.opener) {
            setErrorMsg('No window opener.');
            // Redirect to root path if there's no window opener
            setTimeout(() => {
                window.location.href = '/';
            }, 2000)

        }

        // postMessage to MessageListener in useOAuth2 hook
        else if (error) {
            window.opener.postMessage({
                type: OAUTH_RESPONSE,
                error: decodeURI(error) || 'OAuth error: An error has occurred.',
            });
        } else


        if (state && checkState(state)) {

            window.opener?.postMessage({
                type: OAUTH_RESPONSE,
                payload,
            });
        } else {
            window.opener?.postMessage({
                type: OAUTH_RESPONSE,
                error: 'OAuth error: State mismatch.',
            });
        }
    }, []);

    return Component;
};

export default OAuth2Popup;