import {useEffect, useState} from "react";

export default function Resize(props) {

    const [isResizing, setIsResizing] = useState(false);
    const wrapper = props.appWrapper;
    const [wrapDiagonal, setWrapDiagonal] = useState(wrapper ? Math.hypot(wrapper.offsetHeight,wrapper.offsetWidth) : null);

    function getCoords(elem) {
        let box = elem.getBoundingClientRect();

        let body = document.body;
        let docEl = document.documentElement;

        let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        let clientTop = docEl.clientTop || body.clientTop || 0;
        let clientLeft = docEl.clientLeft || body.clientLeft || 0;

        let topOffset  = box.top +  scrollTop - clientTop;
        let leftOffset = box.left + scrollLeft - clientLeft;

        return { top: Math.round(topOffset), left: Math.round(leftOffset) };
    }

    function initResize() {
        setIsResizing(true);
        setWrapDiagonal( Math.hypot(wrapper.offsetHeight,wrapper.offsetWidth) );
        document.addEventListener("mouseup",endResize);
    }

    useEffect(() => {
        const handleResize = (e) => {
            if (!isResizing) return;
            let translate = window.getComputedStyle(wrapper).transform.match(/\((.*)\)/m)[1].split(", ");
            let xTranslate = parseFloat(translate[4]);
            let yTranslate = parseFloat(translate[5]);
            let wrapperOffset = getCoords(wrapper);

            let zoom =
                Math.hypot(e.pageY-wrapperOffset.top,e.pageX-wrapperOffset.left) /
                wrapDiagonal;
            wrapper.style.transform = `matrix(${zoom},0,0, ${zoom},${xTranslate}, ${yTranslate})`;
        };

        document.addEventListener('mousemove', handleResize);

        return () => {
            document.removeEventListener('mousemove', handleResize);
        };
    }, [isResizing,wrapDiagonal,wrapper]);


    function endResize(e) {
        document.removeEventListener("mouseup",endResize)
        setIsResizing(false);

    }

    return(
        <div className="Resize-handle" onMouseDown={initResize} >

        </div>
    )

}