
import './styles/App.css';
import './styles/containers.css';
import './styles/table-of-contents.css';
import './styles/player.css';
import './styles/nav.css';
import './styles/user-form.css';
import './styles/episode-view.css';
import './styles/animate.css';
import './styles/responsive.css';
import './styles/custom-bootstrap.css';

import { withCookies } from 'react-cookie';
import {lazy, Suspense, useEffect, useRef, useState} from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { store } from "./redux/store";
import MenuBar from "./components/MenuBar";
import readTrackTags from "./methods/readTrackTags";
import AudioPlayer from "./components/AudioPlayer";
import Resize from "./components/Resize";
import  {fetchRssPaidContents} from "./methods/paidEpisodes";
import UserForm from "./components/UserForm";
import {Col, Row} from "react-bootstrap";
import Artwork from "./components/Artwork";
import {AudioContextProvider, useAudioContext} from "./hooks/AudioContext";
import {selectUserPreferredLang, selectUserRssFeed} from "./redux/steadyUserSlice";
import {
    setIsPlaying,
    togglePlayback
} from "./redux/audioPlayerSlice";
import {
    selectAudioSrc,
    selectCurrentEpisode,
    selectHasInit,
    setArtwork,
    setCurrentEpisode,
    setHasInit
} from "./redux/currentEpisodeSlice";
import { setAllChapters, setCurrentChapter} from "./redux/chaptersSlice";
import {
    selectAllEpisodes,
    selectEpisodeCount,
    selectHasRssError,
    setAllEpisodes, setBasicEpisodes,
    setRssError
} from "./redux/episodesSlice";
import {selectEpisodeListHasFocus, selectPlayerHasFocus, selectUserFormHasFocus} from "./redux/domSlice";
import EpisodeTitle from "./components/EpisodeTitle";
import {renewToken, selectAccessToken, selectTokenExpiresAt} from "./redux/steadyAuthSlice";


function App() {

    // Initialize Redux store constants
    const dispatch = useDispatch();
    const currentEpisode = useSelector(selectCurrentEpisode);
    const allEpisodes = useSelector(selectAllEpisodes);
    const userRssFeed = useSelector(selectUserRssFeed);
    const hasInit = useSelector(selectHasInit);
    const userFormHasFocus = useSelector(selectUserFormHasFocus);
    const episodeListHasFocus = useSelector(selectEpisodeListHasFocus);
    const playerHasFocus = useSelector(selectPlayerHasFocus);
    const audioSrc = useSelector(selectAudioSrc);
    const episodeCount = useSelector(selectEpisodeCount);
    const hasRssError = useSelector(selectHasRssError);
    const accessToken = useSelector(selectAccessToken);
    const tokenExpiresAt = useSelector(selectTokenExpiresAt);

    const Episodes = lazy(() => import("./components/Episodes"));


    const [hasLoadedBasicLocal, setHasLoadedBasicLocal] = useState(false);
    const [hasLoadedPremiumLocal, setHasLoadedPremiumLocal] = useState(false);
    const [userRssHasChangedLocal, setUserRssHasChangedLocal] = useState(false);
    const [loadingBasicLocal, setLoadingBasicLocal] = useState(false);
    const userLang = useSelector(selectUserPreferredLang) || "de";
    const [langHasChanged, setLangHasChanged] = useState(false);
    const [isEnAvailable, setIsEnAvailable] = useState(false);


    // Setup references and

    // cookies: rssFeed, userMail and steady-token
    // const [] = useCookies(['rssFeed','userMail','steady-token']);
    const appWrapper = useRef();
    const playerWrapper = useRef();

    const CURRENT_GUID_REF = useRef(null);

    const AUDIO_REF = useAudioContext();


    /**
     * Trying to remove the following block
     * variables are stored persistently in Redux store
     */

    /*
    // Inits or updates the state variables for userRssFeed, userMail and accessToken
    useEffect(() => {
        const storedRssFeed = cookies.rssFeed || localStorage.getItem('rssFeed');
        const storedUserMail = cookies.userMail || localStorage.getItem('userMail');

        if (storedRssFeed) {
            dispatch(setUserRssFeed(storedRssFeed));
        }
        if (storedUserMail) {
            dispatch(setUserMail(storedUserMail));
        }
    }, [dispatch, cookies.rssFeed, cookies.userMail]);
     */


    /*
    Request english content and check availability
     */

    async function checkEnglishContentAvailability() {
        try {
            const response = await fetch('/api/episodes/en');
            if (response.ok) {
                setIsEnAvailable(true);
            } else {
                setIsEnAvailable(false);
            }
        } catch (error) {
            console.error('Error checking English content availability:', error);
        }
    }

    useEffect(() => {
        checkEnglishContentAvailability();
    }, []);


    function togglePlayPause(e) {
        dispatch(setIsPlaying((prev) => !prev))
    }

    useEffect(() => {
        CURRENT_GUID_REF.current = currentEpisode.guid;
        if (accessToken & new Date().getTime() > tokenExpiresAt) {
            console.info("Renewing token")
            dispatch(renewToken()) ;
        }
    },[currentEpisode,dispatch, accessToken, tokenExpiresAt])

    useEffect(() => {
        setHasLoadedBasicLocal(false);
        setHasLoadedPremiumLocal(false)
        setUserRssHasChangedLocal(true)
    },[userRssFeed])

    useEffect(() => {
        setLangHasChanged(true);
        setLoadingBasicLocal(false);
        setHasLoadedBasicLocal(false);
        setHasLoadedPremiumLocal(false)
    },[userLang])



    useEffect(() => {
        if (userRssHasChangedLocal || langHasChanged) {
            if (episodeCount < 1 || langHasChanged) {
                async function fetchRssData() {
                    try {
                        let data;
                        if (userLang === "en") {
                            const response = await fetch("/api/episodes/en");
                            data = await response.json();
                        }
                        else if (userLang === "de") {
                            const response = await fetch("/api/episodes");
                            data = await response?.json();
                        }
                        dispatch(setAllEpisodes(data));
                    } catch (error) {
                        console.error("Error fetching RSS data:", error);
                    } finally {
                        setLoadingBasicLocal(false);
                        setHasLoadedBasicLocal(true);
                        setUserRssHasChangedLocal(false);
                        setLangHasChanged(false);
                    }
                }

                if (!loadingBasicLocal && !hasLoadedBasicLocal) {
                    fetchRssData();
                    setLoadingBasicLocal(true);
                }
            } else if (!hasLoadedBasicLocal) {
                dispatch(setBasicEpisodes());
                setHasLoadedBasicLocal(true);
                setUserRssHasChangedLocal(false);
            }
        }
    }, [loadingBasicLocal, dispatch, userRssHasChangedLocal, hasLoadedBasicLocal, episodeCount, userLang, langHasChanged]);


    useEffect(() => {
        if (userRssFeed && episodeCount > 0 && hasLoadedBasicLocal === true && !hasLoadedPremiumLocal) {
            fetchRssPaidContents(userRssFeed, allEpisodes)
                .then((res) => {
                    if (res !== allEpisodes) dispatch(setAllEpisodes(res));
                    dispatch(setRssError(false));
                })
                .catch((err) => {
                    dispatch(setRssError(true));
                })
                .finally(() => {
                    setHasLoadedPremiumLocal(true);
                    setUserRssHasChangedLocal(false);
                });
        } else if (!userRssFeed) {
            dispatch(setRssError(true));
        }
    }, [hasLoadedPremiumLocal, hasRssError, dispatch, userRssFeed, episodeCount, allEpisodes, hasLoadedBasicLocal, userRssHasChangedLocal]);


    useEffect(() => {
        if(!currentEpisode?.src && episodeCount > 0 && !hasInit ) {
            setTimeout(() => {
                const initCurrentEpisode = allEpisodes[Math.floor(Math.random()*episodeCount)];
                if( initCurrentEpisode?.src ) {
                    dispatch(setCurrentEpisode(initCurrentEpisode));
                }
            },100)
        }
    },[allEpisodes,currentEpisode,dispatch, episodeCount, hasInit])


    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === ' ' && playerHasFocus && !(e.target.type === "text") && audioSrc) {
                dispatch(togglePlayback());
            }
        };

        document.addEventListener('keypress', handleKeyPress);

        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [audioSrc, playerHasFocus, AUDIO_REF, dispatch]);


    // Load table of contents as CTOC from readTrackTags
    useEffect(() => {
        const loadCTOC = async () => {
            if (!currentEpisode) return null;
            dispatch(setAllChapters(currentEpisode?.toc));
            dispatch(setCurrentChapter("Intro"));
            readTrackTags(currentEpisode.src).then(([CTOC, artworkData, artworkFormat, artworkFallback]) => {
                if( CURRENT_GUID_REF.current === currentEpisode.guid) {
                    if (CTOC) dispatch(setAllChapters(CTOC));
                    dispatch(setArtwork({data: artworkData, format: artworkFormat, fallback: artworkFallback}));
                    if(currentEpisode?.guid) dispatch(setHasInit(true));
                    // setCurrentTitle(title);
                }
            }).catch(error => {
                return <div>{error}</div>;
            });
        };
        loadCTOC();
    }, [dispatch,currentEpisode]);

    if (!hasInit) {
        //return <div>Loading...</div>;
    }

    const playerClass = userFormHasFocus
        ? 'player player--bottom'
        : (episodeListHasFocus)
        ? 'player player--left'
        : 'player';


    return (
        <div className="audio-player-wrapper" ref={appWrapper}>
            <div className="audio-player-background"></div>
            { userFormHasFocus && <UserForm /> }
            <player className={playerClass} ref={playerWrapper}>
                <header className="audio-header">
                    <MenuBar playerWrapper={playerWrapper} isEnAvailable={isEnAvailable} /*tocRef={TOC}*/ />
                    <EpisodeTitle />
                </header>
                <main>
                    <div className="main-spacer"></div>
                    <Row className="row flex-nowrap align-items-center p-0 gap-2">
                        <Col md lg={12}>
                            <Artwork />
                        </Col>
                    </Row>
                    <AudioPlayer /*tocRef={TOC}*/ togglePlayPause={togglePlayPause} />
                </main>
                <footer>
                    <Resize appWrapper={appWrapper.current} />
                </footer>
            </player>
            <Suspense fallback={<div>Lädt</div>}>
                <Episodes />
            </Suspense>
        </div>
    );
}

const AppWithProvider = () => (
    <Provider store={store}>
        <AudioContextProvider>
            <App />
        </AudioContextProvider>
    </Provider>
)

export default withCookies(AppWithProvider);


/*
    useDebouncedEffect(() => {
        let chap = currentEpisode?.toc || chaptersFromTrack;
        console.log("debug3")
        let currentChap = chap.findIndex((e) => {
            return (e.startTime > AUDIO_REF.current??.currentTime)-1
        })
        setCurrentChapter(chap[currentChap]?.chapterTitle || null);
        console.log(currentChapter)
    },1000,[chaptersFromTrack,currentEpisode,AUDIO_REF.current??.currentTime])

     */