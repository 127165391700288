// episodesSlice.js
import { createSlice } from "@reduxjs/toolkit";

const storedFavourites = JSON.parse(localStorage.getItem("favourites")) || [];

const episodesSlice = createSlice({
    name: "episodes",
    initialState: {
        allEpisodes: [],
        count: 0,
        hasLoaded: false,
        rssError: true,
        focusTopic: null,
        currentEpisode: null,
        favourites: storedFavourites,
    },
    reducers: {
        setAllEpisodes: (state, action) => {
            state.hasLoaded = false;
            state.allEpisodes = action.payload;
            state.count = action.payload?.length ?? 0;

            if (action.payload?.length > 1) {
                state.hasLoaded = true;
            } else if (action.payload?.length <= 1) state.rssError = true;
        },
        setRssError: (state, action) => {
            state.rssError = action.payload;
        },
        setFocusTopic: (state, action) => {
            state.focusTopic = action.payload;
        },
        setBasicEpisodes: (state) => {
            state.allEpisodes = state.allEpisodes.map((episode) => {
                if (!episode.isFreebie) {
                    return { ...episode, src: "" };
                }
                return episode;
            });
        },
        setCurrentGuid: (state,action) => {
            state.currentEpisode = action.payload;
        },
        setFavourite: (state, action) => {
            const uuidIndex = state.favourites.indexOf(action.payload);
            if (uuidIndex === -1) {
                // Add UUID to favourites if it's not in the array
                state.favourites = [...state.favourites, action.payload];
            } else {
                // Remove UUID from favourites if it's already in the array
                state.favourites = state.favourites.filter(uuid => uuid !== action.payload);
            }
        },
    },
});

export const { setAllEpisodes, setRssError, setFocusTopic, setBasicEpisodes, setCurrentGuid, setFavourite } = episodesSlice.actions;

export const selectHasRssError = (state) => state.episodes.rssError;
export const selectAllEpisodes = (state) => state.episodes.allEpisodes;
export const selectAllEpisodesHasLoaded = (state) => state.episodes.hasLoaded;
export const selectEpisodeCount = (state) => state.episodes.count;
export const selectFavourites = (state) => state.episodes.favourites;

export default episodesSlice.reducer;