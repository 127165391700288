// domSlice.js
import { createSlice } from "@reduxjs/toolkit";

const domSlice = createSlice({
    name: "dom",
    initialState: {
        focus: "player",
        previousFocus: null,
        player: true,
        userForm: false,
        episodeList: false,
        chapterList: false,
    },
    reducers: {
        setFocus: (state, action) => {
            const componentToFocus = action.payload;

            state.previousFocus = state.focus;
            state.focus = componentToFocus;

            // Reset the focus for all components
            state.player = false;
            state.userForm = false;
            state.episodeList = false;
            state.chapterList = false;

            // Set the focus for the selected component
            state[componentToFocus] = true;

        },
        toggleFocus: (state, action) => {
            if (state.focus !== "player") {
                // Reset the focus for all components
                state.player = true;
                state[action.payload] = false;

                state.focus = "player";
                state.previousFocus = action.payload;
            } else {
                state.player = false;
                state[action.payload] = true;
                state.focus = action.payload;
                state.previousFocus = "player";
            }
        }
    },
});

export const { setFocus, toggleFocus } = domSlice.actions;

export const selectHasFocus = (state) => state.dom.focus;
export const selectPlayerHasFocus = (state) => state.dom.player;
export const selectUserFormHasFocus = (state) => state.dom.userForm;
export const selectEpisodeListHasFocus = (state) => state.dom.episodeList;
export const selectChapterListHasFocus = (state) => state.dom.chapterList;

export default domSlice.reducer;
