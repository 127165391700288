import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import {selectUserPreferredLang, setUserPreferredLang} from "../redux/steadyUserSlice";
import {useDispatch, useSelector} from "react-redux";

const LanguageSwitch = ({isEnAvailable}) => {
    const userLang = useSelector(selectUserPreferredLang);
    const dispatch = useDispatch();

    const handleLanguageChange = (newLanguage) => {
        dispatch(setUserPreferredLang(newLanguage));
    };


    return (
        <ButtonGroup className="language-toggle language-toggle__button-group {
">
            <Button
                className="language-toggle language-toggle__button language-toggle__button--de"
                variant={!userLang || userLang === 'de' ? 'dark' : 'light'}
                onClick={() => handleLanguageChange('de')}
            >
                DE
            </Button>
            <Button
                className="language-toggle language-toggle__button language-toggle__button--en"
                variant={userLang === 'en' ? 'dark' : 'light'}
                disabled={!isEnAvailable}
                onClick={() => handleLanguageChange('en')}
            >
                EN
            </Button>
        </ButtonGroup>
    );
};

export default LanguageSwitch;
