import React, {useEffect} from "react";
import LoginWithOAuth2 from "./Login";
import {useCookies, withCookies} from "react-cookie";
import Logout from "./Logout";
import {useDispatch, useSelector} from "react-redux";
import {selectAccessToken, selectIsAuthenticated} from "../redux/steadyAuthSlice";
import {setUserData, setUserRssFeed} from "../redux/steadyUserSlice";


function OAuth() {

    const accessToken = useSelector(selectAccessToken);
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const dispatch = useDispatch()

    const [, setCookies, removeCookies] = useCookies(['rssFeed','userMail','steady-token']);



    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('steady-user'))?.info;
        if (userInfo) setUserData({
                userFirstName: userInfo["first-name"],
                userLastName: userInfo["last-name"],
                userId: userInfo["id"]
            }
        )
    },[])


    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(`/api/user?access_token=${accessToken}`);
                if (!response.ok) {
                    throw new Error(`Error fetching user data: ${response.statusText}`);
                }
                const res = await response.json();
                const rssFeedUrl = res.data.attributes["rss-feed-url"];
                dispatch(setUserRssFeed(rssFeedUrl));

            } catch (error) {
                console.error(error.message);
            }
        }
        if (accessToken) {
            fetchData();
        }
    }, [accessToken, isAuthenticated, dispatch]); // Make sure to include 'dispatch' in the dependency array





    if(!accessToken) return (
        <LoginWithOAuth2 setCookies={setCookies} />
    )

    else if(accessToken) return (
        <Logout removeCookies={removeCookies} />
    )

}

export default withCookies(OAuth);