import {createContext, useContext, useRef} from "react";

const AudioContext = createContext(null);

export function useAudioContext() {
    return useContext(AudioContext);
}

export function AudioContextProvider({ children }) {
    const audioRef = useRef(null);

    return <AudioContext.Provider value={audioRef}>{children}</AudioContext.Provider>;
}