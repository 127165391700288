import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import {useDispatch} from "react-redux";
import {setUnAuth} from "../redux/steadyAuthSlice";

export default function Logout(props) {
    const [loading, setLoading] = useState(false)
    const removeCookies = props.removeCookies;
    const dispatch = useDispatch();

    function handleClick() {
        setLoading(true);
        localStorage.removeItem('steady-user');
        removeCookies('steady-token');
        setTimeout(() => {
            dispatch(setUnAuth())
            setLoading(false);
        },2000)
    }


    return(
            <Button className="col-auto steady-button" id="steady-button" variant={props.saved ? "success" : "primary"} type="submit" onClick={handleClick} disabled={loading} data-toggle="popover" title="Data saved" data-content="We have saved your RSS feed and your address in your browser.">
                <img src="https://assets.steadyhq.com/gfx/brand2019/steady_icon_white.svg" alt="" aria-hidden="true" style={{width: "13px"}} />
                <span className="backend_publication_steady_login_settings__preview__button__separator"  >
                    </span>
                <span className="w-100">Logout</span>
            </Button>
    )
}