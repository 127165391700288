import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import {useEffect, useState} from "react";
import OAuth from "../oauth/OAuth";
import {useDispatch, useSelector} from "react-redux";
import {selectUserFormHasFocus, setFocus} from "../redux/domSlice";
import {selectUserMail, selectUserRssFeed, setUserMail, setUserRssFeed} from "../redux/steadyUserSlice";
import {useCookies} from "react-cookie";
import {selectAccessToken, selectIsAuthenticated} from "../redux/steadyAuthSlice";
import {setRssError} from "../redux/episodesSlice";


export default function UserForm() {


    const dispatch = useDispatch();

    const userFormHasFocus = useSelector(selectUserFormHasFocus);
    const userRssFeed = useSelector(selectUserRssFeed);
    const userMail = useSelector(selectUserMail);
    const accessToken = useSelector(selectAccessToken);
    const isAutheticated = useSelector(selectIsAuthenticated)

    const [inputRss, setInputRss] = useState(userRssFeed);
    const [inputMail, setInputMail] = useState(userMail);
    const [inputIsValid, setInputIsValid] = useState(false);
    const [saved, setSaved] = useState(false);
    const [cookies, setCookie] = useCookies(['rssFeed','userMail']);

    useEffect(() => {
        if(isAutheticated) setInputRss(userRssFeed)
    },[isAutheticated,userRssFeed])


    useEffect(() => {
        dispatch(setFocus("userForm"));

        if (!inputRss && !userRssFeed) {
            const prevRss = localStorage.getItem("rssFeed") || cookies.rssFeed;
            setInputRss(prevRss);
        }

        if (!inputMail && userMail) {
            setInputMail(userMail);
        }
    }, [userRssFeed, userMail, cookies, dispatch, inputMail, inputRss]);


    useEffect(() => {
        setInputIsValid(validateRssInput(inputRss));
    },[inputRss])


    const formStyle = {
        top: userFormHasFocus ? '4em' : '-25em',
        transition: 'top 500ms linear 250ms'
    };

    function handleRssFeedChange(e) {
        if(e.target.id === "rssFeed") setInputRss(e.target.value);
        else if(e.target.id === "userMail") setInputMail(e.target.value);
        setSaved(false);
    }

    function handleSubmit(e) {
        e.preventDefault();
        localStorage.setItem("userMail", inputMail);
        _handleRssFeedSubmit();
        setTimeout(() => {
            dispatch(setFocus("player"))
        },2500)
    }

    function _handleRssFeedSubmit() {
        if (validateRssInput(inputRss)) {
            localStorage.setItem("rssFeed",inputRss);
            dispatch(setUserMail(inputMail));
            dispatch(setUserRssFeed(inputRss));
            setCookie('rssFeed', inputRss);
            setCookie('userMail', inputMail);
            dispatch(setRssError(true));
            setSaved(true);
        } else {
            setInputIsValid(false);
        }
    }

    function validateRssInput (rss) {
        /*
        const UUID_PATTERN = '[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}';
        const UUID = "fdf71e96-6317-4f1b-bda8-f532f3372168";
        const RSS_REGEX = new RegExp(`^https:\/\/steadyhq\.com\/rss\/(?:amboss-audio|${UUID})\?auth=${UUID_PATTERN}$`);
        */
        const REG = /^https:\/\/steadyhq\.com\/rss\/(?:amboss-audio|fdf71e96-6317-4f1b-bda8-f532f3372168)\?(?:auth|pub_auth)=[a-z0-9A-Z_-]*$/;


        return REG.test(rss);
    }

    /**
     * Boolean statement to check whether RSS input is correct unless it is empty.
     * @type {boolean}
     */
    const isDisabled = inputRss !== "" && (saved || !inputIsValid || accessToken);


    return (
        <Form style={formStyle}>
            <Form.Group className="mb-3" controlId="userMail" onChange={handleRssFeedChange} >
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" name="userMail" placeholder="name@example.com" value={inputMail}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="rssFeed">
                <Form.Label>SteadyHQ RSS-Feed</Form.Label>
                <Form.Control name="rssFeed" aria-describedby="basic-addon3" as="textarea" rows={4} onChange={handleRssFeedChange} value={inputRss} isValid={inputIsValid} isInvalid={!inputIsValid && inputRss !== ""}  />
                <Form.Control.Feedback type="invalid">
                    The RSS feed doesn't seem to work.
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                    Paste your RSS-Feed URL here to gain access to your personal AMBOSS Audio episodes.
                </Form.Text>
            </Form.Group>
            <Form.Group className="row md-auto flex-start justify-content-around gy-3">
                <Button className="col-auto" variant={!saved ? "primary" : inputIsValid ? "success" : "danger" }
                        disabled={ isDisabled } aria-disabled={isDisabled} tabIndex={isDisabled ? -1 : 0}
                        type="submit" onClick={handleSubmit} data-toggle="popover" title="Data saved" data-content="We have saved your RSS feed and your address in your browser.">
                    Update
                </Button>
                <OAuth />
            </Form.Group>


        </Form>
    );
}


// import {mapStateToPropsFactory} from "react-redux/es/connect/mapStateToProps";


/*
const mapStateToProps = (state) => {
    return {
        userFormHasFocus: selectUserFormHasFocus(state),
        hasRssError: selectHasRssError(state),
        userRssFeed: selectUserRssFeed(state),
        userMail: selectUserMail(state),
        isAuthenticated: selectIsAuthenticated(state),
        accessToken: selectAccessToken(state),
    };
};
*/

/*
 const {
     userFormHasFocus,
     hasRssError,
     userRssFeed,
     userMail,
     isAuthenticated,
     accessToken,
 } = props;
 */

// export default connect(mapStateToProps)(UserForm)
