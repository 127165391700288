// currentEpisodeSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {setPlayerReset} from "./audioPlayerSlice";
import {useDispatch} from "react-redux";

const currentEpisodeSlice = createSlice({
    name: "currentEpisode",
    initialState: {
        episode: {
            guid: null,
            topic: null,
            title: null,
            subtitle: null,
            description: null,
            keywords: null,
            toc: null,
            src: null,
            isFreebie: false,
            xid: false,
            dop: null,
            matchCount: 0,
        },
        hasInit: false,
        hasPlayed: 0,
        artwork: {
            data: null,
            format: null,
            fallback: true,
        },
    },
    reducers: {

        setCurrentEpisode: (state, action) => {
            state.episode = action.payload;
        },
        setHasPlayed: (state, action) => {
            state.count = action.payload;
        },
        setArtwork: (state, action) => {
            state.artwork = action.payload;
        },
        setHasInit: (state) => {
            state.hasInit = true;
        },
        setCurrentSrc: (state,action) => {
            state.episode.src = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setCurrentEpisode, (state) => {
            const dispatch = useDispatch();
            dispatch(setPlayerReset(state));
        });
    },
});

export const { setCurrentEpisode, setHasPlayed, setArtwork, setHasInit , setCurrentSrc} = currentEpisodeSlice.actions;

export const selectCurrentEpisode = (state) => state.currentEpisode.episode;
export const selectCurrentXid = (state) => state.currentEpisode.episode?.xid;
export const selectCurrentGuid = (state) => state.currentEpisode.episode?.guid;
export const selectCurrentTitle = (state) => state.currentEpisode.episode?.title;
export const selectCurrentSubtitle = (state) => state.currentEpisode.episode?.subtitle;
export const selectAudioSrc = (state) => state.currentEpisode.episode?.src;
export const selectArtwork = (state) => state.currentEpisode.artwork;
export const selectHasInit = (state) => state.currentEpisode.hasInit;

export default currentEpisodeSlice.reducer;