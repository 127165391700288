import {OverlayTrigger, Popover} from "react-bootstrap";
import {useEffect, useState} from "react";
import { selectAllChapters} from "../redux/chaptersSlice";
import {selectAllEpisodesHasLoaded, selectHasRssError} from "../redux/episodesSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectPlayerHasFocus, selectUserFormHasFocus, toggleFocus} from "../redux/domSlice";
import LanguageSwitch from "./LanguageSwitch";

export default function MenuBar (props) {
    const chapters = useSelector(selectAllChapters);
    const hasRssError = useSelector(selectHasRssError);
    const userFormHasFocus = useSelector(selectUserFormHasFocus)
    const allEpisodesHasLoaded = useSelector(selectAllEpisodesHasLoaded)
    const playerHasFocus = useSelector(selectPlayerHasFocus);

    const dispatch = useDispatch();
    const { playerWrapper, isEnAvailable } = props;


    let minimizeClass = "audio-track-minimize";
    let maximizeClass = "audio-track-maximize";
    let toolClass = "audio-tools"
    let menuClass = "audio-track-menu";
    let toggleClass = "audio-tool-toggle";
    let tocClassList = [];
    tocClassList.push("fas fa-list");
    tocClassList.push(toggleClass);
    if(chapters) tocClassList.push("clickable");
    let toggleId = "audio-toggle-toc";

    const [deadLinkFx, setDeadLinkFx] = useState(false)

    let rssStyle = {
        transform: "scale(1.5)",
        color: "red",
        fontWeight: "bold"
    }

    // apply deadlink css for one second
    // element zoom&shakes for one cycle
    useEffect(() => {
        if (deadLinkFx) setTimeout(() => setDeadLinkFx(false),1000)
    },[deadLinkFx])

    function togglePlayer(el) {
        if(!playerWrapper.current) return null
        // according to domSlice.js el can be one of:
        // "player", "userForm", "episodeList", "chapterList"
        dispatch(toggleFocus(el))
        /*
        // take out from the logic here and put it inside app.js
        // focused : unfocused based on selectPlayerState
        playerWrapper.current?.classList.toggle("unfocused");
        [el].current?.classList.toggle("unfocused");
         */
    }

    function TriggerButton() {

        const popover = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">RSS Fehler</Popover.Header>
                <Popover.Body>
                    Es sieht aus als sei <strong>irgendetwas falsch gelaufen</strong> beim RSS-Import. Schau schnell mal
                    nach, ob der RSS-Link stimmt.
                </Popover.Body>
            </Popover>
        );

        let rssButtonDivClass = [];
        rssButtonDivClass.push(
            userFormHasFocus
                ? maximizeClass
                : minimizeClass
        );

        rssButtonDivClass.push(
            hasRssError
                ? "deadButton"
                : ""
        )

        return (
            <OverlayTrigger
                delay={{show: 250, hide: 400}}
                placement="right"
                overlay={popover}>
                <div className={rssButtonDivClass.join(" ")} onClick={() => togglePlayer("userForm")}>
                    <i className={`fas ${userFormHasFocus ? "fa-window-maximize" : "fa-rss"} clickable ${toggleClass}`}
                       style={ rssStyle }></i>
                </div>
            </OverlayTrigger>
        )
    }


    return(
        <div className={toolClass}>
            { hasRssError
                ? <TriggerButton />
                : (
                    <div className={userFormHasFocus ? maximizeClass : minimizeClass} onClick={() => togglePlayer("userForm")}>
                        <i className={`fas ${userFormHasFocus ? "fa-window-maximize" : "fa-rss"} clickable ${toggleClass} `}></i>
                    </div>
                )
            }

            <LanguageSwitch isEnAvailable={isEnAvailable} />

            <div className={menuClass} onClick={!userFormHasFocus ? () => dispatch(toggleFocus("episodeList")) : setDeadLinkFx} >
                <i className={[
                    ...tocClassList,
                    allEpisodesHasLoaded && playerHasFocus ? "clickable" : "",
                    deadLinkFx ? "deadButton" : ""
                        ].join(" ")} id={toggleId}></i>
            </div>
        </div>
    )

}